import React from "react";
// import Zoom from 'react-reveal/Zoom';


export function SectionTitle(props){
  const bgclass = (props.background)? props.background: 'secondary';
  if (props.flip){
    return (
      // <Zoom>
    <h1 className="family-h1 text-center py-3">
      <span className={"text-" + bgclass}>{props.first}</span> <span className="">{props.second}</span>
    </h1>
    // </Zoom>
    )
  }
  return (
    // <Zoom>
    <h1 className="family-h1 text-center py-3">
      {props.first} <span className={"text-" + bgclass}>{props.second}</span>
    </h1>
    // </Zoom>
    )
}


export function ParaTitle(props){
  if (props.flip){
    return (<h4 className="family-h5 text-center uppercase text-spacing-2 m-0"><span className="text-secondary">{props.first}</span> <span className="">{props.second}</span></h4>)
  }
  return (<h4 className="family-h5 text-center uppercase text-spacing-2 m-0">{props.first} <span className="text-secondary">{props.second}</span></h4>)
}


export function FooterSectionTitle(props){
  return(<h5 className="small text-uppercase mt-5 mb-3 text-black-1">{props.title}</h5>)
}
