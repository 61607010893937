import React from 'react';
import SocialLogin from 'react-social-login';


const Button = ({ children, triggerLogin, ...props }) => (
  <button onClick={triggerLogin} {...props}
    className={"btn btn-block btn-" + props.bg + " btn-lg"}>
    <i className={props.faicon + " mr-2"}></i> {children}
  </button>
)


export default SocialLogin(Button)
