import React from 'react';

import Nav from 'component/navigation';
import {LandingFooter} from 'component/footer';


export default function LandingLayout(props) {
  return (
    <div id={props.id} className="page-wrapper">

      <Nav />

      <div className="landing-content text-black-2 mt-5">
        {props.children}
      </div>

      <LandingFooter />
    </div>
  );
}
