import React from 'react';

import {SectionTitle} from 'component/reuse/title';
import {SubscribeForm} from './../reuse/form_subscription';


export default class SubscribeCTA extends React.Component {
  render (){
    return (
      <section id="subscribe" className="content-section flex-center ">
        <div className="container-fluid">
          <SectionTitle first="Get onboarded to our" second=" free pilot program" background="secondary" />
          <SubscribeForm />
        </div>
      </section>
    )
  }
}