import React from "react";
import Slide from 'react-reveal/Slide';

import { SectionTitle } from 'component/reuse/title';


const ABOUT = [
  {'type': 'text', 'content': "Just press <span class='keyboard box-shadow'>ctrl</span> + <span class='keyboard box-shadow'>space</span> to view the navigator widget on any website."},
  {'type': 'text', 'content': "It's your website's launcher widget just like Alfred."},
  {'type': 'text', 'content': "You can index internal links for your website's easy navigation, or external links that talk about your services and give you users opportunities to connect on different channels."},
  {'type': 'text', 'content': "You can even define actions that any user can easily perform for your service, such as promoting your offering on twitter or other social media platforms."},
  {'type': 'text', 'content': "Just take navigator for a test ride by pressing <span class='keyboard box-shadow'>ctrl</span> + <span class='keyboard box-shadow'>space</span> now."},
]


function AboutComponentItem(props){
  var aboutItem = props.items;

  if (aboutItem.type === "text"){
    return (<div className="family-p mx-4 my-4 text-wide-2" dangerouslySetInnerHTML={{__html: aboutItem.content }}></div>)
  }

  if (aboutItem.type === "list"){
    var rows = [];
    aboutItem.content.forEach(function(listItem, i){
      rows.push(<li key={'about_list_item_'+i} ><div className="" dangerouslySetInnerHTML={{__html: listItem }}></div></li>);
    });

    return (
        <div>
          <div className="" dangerouslySetInnerHTML={{__html: aboutItem.title }}></div>
          <ul className="">
            {rows}
          </ul>
        </div>
    )
  }

  return (<div> SOMETHING WENT WRONG !</div>)
}


export default function AboutComponent(props) {
    const about = {
      content: ABOUT,
      image: {
        // path: '/static/images/about.svg',
        // path: '/static/images/hero.svg',
        path: '/static/images/colours/green.png',
        alt: 'Launcher for your website that gives your users superpowers to navigate your content',
      },
    }

    return (
      <section id="about" className="content-section">
        <div className="slant slant-top-right-secondary-1"></div>
        <div className="content-section highlight-secondary-1">

          <SectionTitle first="Navigator explained" second="" flip={true}/>
          
          <div className="container-fluid text-black-4">

            <div className="row">
              <div className="col-md-6 flex-center text-center">
                <Slide left>
                <div className="img img-fluid  animated slideInLeft">
                  <img className="hero box-shadow" src={about.image.path} alt={about.image.alt} />
                </div> 
                </Slide>
              </div>

              <div className="col-md-6">
                <Slide right>
                {about.content.map(function(aboutItem, i){
                  return (<AboutComponentItem items={aboutItem} key={'about_item_' + i}/>)
                })}
                </Slide>
              </div>

            </div>              
          </div>

        </div>
        <div className="slant slant-bottom-left-secondary-1"></div>
      </section>
    )
}