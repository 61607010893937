import React from "react";
import Slide from 'react-reveal/Slide';

import {SectionTitle} from 'component/reuse/title';

export default function ComponentColours(props){
  return (
    <div id="colours" className="content-container my-5">
        <div className="container-fluid">
          <div className="">
          <SectionTitle first="Choose your" second="Colour" background="primary" /> 
          </div>

          {/* <div className="row">
            {['red', 'blue', 'green', 'midnight', 'calm', 'shadow'].map(function(colour, i){
              return (
                <div key={'_idx_' + i} className="col col-xl-4 col-sm-12 flex-center">
                  <img className="img img-fluid box-shadow" src={'/static/images/colours/'+colour+'.png'} alt={colour + " colour color"} />
                  <p className="uppercase my-3">{colour}</p>
                </div>  
              )
            })}
          </div> */}

          <ChooseColourComponent />

        </div>
    </div>
  )
}


class ChooseColourComponent extends React.Component {

  state = {
    picked_colour: 'blue',
    colour_map: {
      'red': '#843339',
      'blue': '#5e89af', 
      'green': '#5ba565', 
      'violet': '#5a45b5', 
      'purple': '#7c46b6', 
      'black': '#404347',
      "yellow": '#918a39',
      "gold": '#C79810',
    },
    additional_colour_map: {
      "orange": '#404347',
    }
  }

  onClick(e){
    e.preventDefault();

    const state = this.state;
    state.picked_colour = e.currentTarget.dataset.colour;
    this.setState(state);
  }

  render (){

    const picked_colour = this.state.picked_colour;

    return(
      <div className="">
        <div className="row">
          <div className="col-md-6">
          {/* <Slide left> */}

            <div className="row">
            {Object.keys(this.state.colour_map).map(function(colour, i){
              const selected = picked_colour === colour;
              const pxl = selected? '0': '3';
              const padding = selected? '3px': '0px';
              return (
                <a 
                  key={'_idx_' + i} 
                  onClick={this.onClick.bind(this)} 
                  data-colour={colour} 
                  href="/" 
                  style={{
                    "textDecoration": null,
                    "padding": padding,
                    "borderLeft": pxl + "px solid " + this.state.colour_map[colour], 
                    "borderRight": pxl + "px solid " + this.state.colour_map[colour], 
                    "borderTop": pxl + "px solid " + this.state.colour_map[colour],
                    "borderBottom": pxl + "px solid " + this.state.colour_map[colour],
                    "color": this.state.colour_map[colour]

                  }}
                  className={"col-md-3 m-2 btn-rounded text-center" + ((selected)? " box-shadow": "")}>
                  {colour.charAt(0).toUpperCase() + colour.slice(1)}
                </a>
              )
              }, this)}
            </div>

            {/* <div className="container">
              <hr className="black-bb-1-1"/>
            </div>

            <h5>Additional colours</h5>
            <div className="row">
            {Object.keys(this.state.additional_colour_map).map(function(colour, i){
              return (
                <span 
                  key={'_idx_' + i} 
                  style={{
                    "borderLeft": "6px solid " + this.state.additional_colour_map[colour], 
                    "borderRight": "6px solid " + this.state.additional_colour_map[colour], 
                    "borderTop": "3px solid " + this.state.additional_colour_map[colour],
                    "borderBottom": "3px solid " + this.state.additional_colour_map[colour]
                  }}
                  className={"text-black-2 text-center col-3 m-2 btn-rounded"}>
                  {colour.charAt(0).toUpperCase() + colour.slice(1)}
                </span>
              )
            }, this)}
            </div> */}

            {/* </Slide> */}
          </div>
          <div className="col-md-6">
            <Slide right>
            <img className="img img-fluid box-shadow" src={'/static/images/colours/'+picked_colour+'.png'} alt={picked_colour + " colour color"} />
            </Slide>
          </div>
        </div>
      </div>
    )
  }
}