import React from "react";


export default function Component(props){
  return (<ByteTrackTryout/>)
}


// function BlogCallToIdea(props){
//   return (
//     <div className="bg-secondary text-white text-center py-2">
//       <span role="img" aria-label="product announcement">💡</span>
//       <span className="">@ Domain experts with product ideas</span>: 
//       Interested in free conversations with developers who can help build a free pilot offering?
//       <i className="fa fa-caret-right mx-2"></i>
//       <a className="strong text-white" href="https://blog.bytebeacon.com/2020/05/13/celebrating-builders-during-a-pandemic"> Checkout our blog!</a>
//     </div>
//   )
// }


function ByteTrackTryout(props){
  return (
    <div className="bg-danger text-white text-center py-2">
      <span role="img" aria-label="product announcement">💡</span>
      <a href="https://bytetrack-app.bytebeacon.com/public" className="text-white">
        Also checkout <strong className="">ByteTrack </strong>:
        Track recurring events and set habits for the long term!
      </a>
    </div>
  )
}
