import React from 'react';
import { withRouter} from 'react-router-dom';
// import CookieConsent from "react-cookie-consent";

import LandingLayout from 'component/page/landing';
import Build from 'component/content/build';


class Page extends React.Component {
  render (){
    return (
      <LandingLayout id="build-page">
        <Build />
      </LandingLayout>
    )
  }
}

export default withRouter(Page);
