import React from 'react';
import { withRouter} from 'react-router-dom';

import ContentLayout from 'component/page/content';
import API, {handleError} from 'utils/xhr';


class PingPage extends React.Component {
  state = {
    ping_data: null
  }

  componentDidMount(){
    const url = '/users/ping';

    API.get(url).then(response => {
      this.setState({
        ping_data: response.data,
      });

    }, (error) => {
      handleError(error);
    });
  }

  render (){

    return (
      <ContentLayout id="ping-page">
        <div className="jumbotron">
            <div className="contaainer">
                <h1>PingPage</h1>
                <div>
                {(this.state.ping_data) === null? '' : (
                  <p>Request id: {this.state.ping_data.meta.request_id}</p>
                )}
                </div>
            </div>
        </div>
      </ContentLayout>
    )
  }
}

export default withRouter(PingPage);
