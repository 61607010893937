import React from 'react';
import { withRouter, Link, NavLink} from 'react-router-dom';

import { hasStoredToken, deleteToken } from 'utils/authentication';
import FullStory from 'react-fullstory';
import Announcement from 'component/content/announcement';

class Nav extends React.Component {

  constructor (props) {
    super(props);
    this.logout = this.logout.bind(this);
  }

  logout () {
    deleteToken();
    this.props.history.push('/');
  }

  render () {
    const ORG_ID = process.env.REACT_APP_FULLSTORY_ORG_ID;

    return(
      <section className={ ((hasStoredToken())? (' '): (' fixed-top')) }>

        {((hasStoredToken())? (' '): (<Announcement />))}
        
      <nav className={"navbar navbar-expand-lg bg-white box-shadow p-0 "}>
        <FullStory org={ORG_ID} />

        <div className="navbar-header">
          {(hasStoredToken())? (
            <div className="">
            <Link to="/" className="navbar-brand mr-2 text-size-1 family-title">
              <img style={{height: "40px"}} className="hero mx-3" src="/logo.svg" alt="logo svg" />
              <span className="text-black-2">Navigator</span>
            </Link>  
            </div>
          ): (
            <Link to="/" className="navbar-brand mr-2 text-size-1 family-title">
              <img style={{height: "40px"}} className="hero mx-3" src="/logo.svg" alt="logo svg" />
              <span className="text-black-2">Navigator</span>
            </Link>  
          )}
        </div>

        {/* <button className="navbar-toggler btn btn-primary text-white mr-2" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <i className="fas fa-bars"></i>
        </button> */}

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item active">
            </li>
          </ul>

          { hasStoredToken() ? (
            <ul className="navbar-nav my-0 my-lg-0">
              <li className="nav-item">
                <NavLink exact activeClassName="highlight-primary-6" className="highlight-primary-1 btn btn-rounded mx-1 uppercase" to="/"><span className="fa fa-list mr-2"></span>Home</NavLink>
              </li>
              <li className="nav-item">
                <NavLink exact activeClassName="highlight-primary-6" className="highlight-primary-1 btn btn-rounded mx-1 uppercase" to="/billing"><span className="fa fa-coins mr-2"></span>Billing</NavLink>
              </li>

              <li className="nav-item dropdown">
                <Link className=" btn mx-1 uppercase dropdown-toggle" to="/" id="navbarDropdown1" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <span className="fa fa-cog">&nbsp;</span>Settings&nbsp;
                </Link>
                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown1">
                  <Link className="dropdown-item" to="/">Home</Link>
                  <hr className="my-2"/>
                  <Link className="dropdown-item" to="/legal/tos">Terms</Link>
                  <Link className="dropdown-item" to="/legal/privacy">Privacy</Link>
                  <hr className="my-2"/>
                  <button className="dropdown-item" onClick={this.logout}>
                    <span className="fa fa-power-off">&nbsp;</span>Logout
                  </button>
                </div>
              </li>

            </ul>
          ): (
            <ul className="navbar-nav my-0 my-lg-0">
              <li className="nav-item">
                <Link to="/build" className="btn btn-outline-primary btn-rounded text-spacing-2 m-2 uppercase"><i className="fa fa-paper-plane mr-2"></i>Build now</Link>
              </li>
              {/* <li className="nav-item">
                <Link to="/login" className="btn btn-outline-primary btn-rounded text-spacing-2 m-2 uppercase"><i className="fa fa-sign-in-alt mr-2"></i>Login</Link>
              </li> */}
            </ul>
          )}

        </div>
      </nav>
      </section>
    )
  }
}


export default withRouter(Nav);
