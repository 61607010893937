import React from "react";
import Fade from 'react-reveal/Fade';

import {SubscribeForm} from './../reuse/form_subscription';


export default class HeroSection extends React.Component {

  render (){
    const hero = {
      title: {
        jumbo:{
          pre: 'Smart ',
          focus: ' search bar ',
          post: ' for your website', 
        },
        sub: {
          pre: 'Superpowers for your users with an easy to integrate',
          focus: ' launcher ',
          post: " for your website", 
        },
      },
      features: [
        'NoCode setup',
        'Customizable',
        'Analytics & Reports',
      ],
      image: {
        // path: '/static/images/hero.svg',
        path: '/presskit/demo.gif',
        alt: "Launcher for your website that gives your users superpowers to navigate your content",
      },
    }

    return (
      <section id="hero" className=" my-5">
        <HeroTitleSubFeaturesImageComponent hero={hero} />
      </section>
    )
  }
}

function HeroTitleSubFeaturesImageComponent(props){
  const hero = props.hero;
  return (
    <div className=" justify-content-between">

      <div className="mt-5 mb-4">
        <h1 className="family-h1 text-center jumbo-title">
          <span className="text-black-2">{hero.title.jumbo.pre}</span>
          <span className="text-primary">{hero.title.jumbo.focus}</span>
          <span className="text-black-2">{hero.title.jumbo.post}</span>
        </h1>
      </div>

      <div className="my-3 text-center">
        <h4 className="my-2 family-p text-spacing-2">
          <span className="keyboard mx-2">ctrl</span> + <span className="keyboard mx-2">space</span>: to open <span className="text-primarya">navigator</span>
          {/* {hero.title.sub.pre}
          <span className="text-primary">{hero.title.sub.focus}</span> 
          {hero.title.sub.post} */}
        </h4>
      </div>

      {/* <div className="my-5 text-center">
        <div className="row text-spacing-2 family-code small uppercase ">
          <span className="col ">{hero.features[0]}</span>
          <span className="col ">{hero.features[1]}</span>
          <span className="col ">{hero.features[2]}</span>
        </div>
      </div> */}

      {/* <div className="mt-4">
        <div className="flex-center">
          <h5 className="">Superpowers @ <span className="keyboard box-shadow mx-3">ctrl</span> + <span className="keyboard box-shadow mx-3">space</span></h5>
        </div>
      </div> */}


      {/* <div className="my-5 animated slideInLeft">            
        <div className="flex-center">
          <Link to="/login" className="btn btn-secondary text-white btn-rounded  btn-lg p-3 uppercase text-spacing-3 disabled">
            Launching soon
          </Link>
        </div>
      </div> */}

      <div className="container flex-center my-5">
        <Fade>
        <div className=" col-md-6 img img-fluid text-center animated slideInRight btn-rounded">
          <img className="hero my-2" src={hero.image.path} alt={hero.image.alt} />
          <p className="">Press <span className="keyboard mx-2">ctrl</span> + <span className="keyboard mx-2">space</span> and play with the live widget</p>
        </div> 
        </Fade>
      </div>

      <div className="container flex-center">
        <SubscribeForm />
      </div>


    </div>
  )
}
