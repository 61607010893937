import React from 'react';

import SocialComponent from './../reuse/social';
import { PROFILE } from "./../constant";

import {FooterExtLink, FooterSectionTitle} from './base';


export function CopyrightFooter(props){
  return (
    <div className={" family-code bg-dark text-center py-" + props.padding}>
      <div className="text-white">© { new Date().getFullYear() } - <a className=" uppercase text-spacing-2" href="https://bytebeacon.com">ByteBeacon, Inc</a> - All rights reserved.</div>
    </div>
  )
}



export function CompanyComponent(props){
  const is_mobile = props.is_mobile;
  return (
    <div className={" text-center" + ((is_mobile)? ' mx-3 px-3': " m-1")}>
      <FooterSectionTitle title="Company"/>
      <div className="group-vertical">
        <FooterExtLink href="https://bytebeacon.com" text="Home" />
        <FooterExtLink href="https://blog.bytebeacon.com" text="Blog" />
        <FooterExtLink href="https://bytebeacon.com/legal/privacy" text="Privacy" />
        <FooterExtLink href="https://bytebeacon.com/legal/tos" text="Terms" />
        <FooterExtLink href="https://angel.co/company/bytebeacon/jobs" text="Jobs" />
      </div>
    </div>
  )
}

export function OtherProductsComponent(props){
  const is_mobile = props.is_mobile;
  return (
    <div className={" text-center" + (is_mobile)? ' text-center': " m-1"}>
      <FooterSectionTitle title="Other products"/>
      <div className="">
        <FooterExtLink href="https://bytepost.bytebeacon.com" text="BytePOST" />
        <FooterExtLink href="https://softcomm.bytebeacon.com" text="SoftComm" />
        <FooterExtLink href="https://navigator.bytebeacon.com" text="Navigator" />
        <FooterExtLink href="https://divshow.bytebeacon.com" text="DivShow" />
      </div>
    </div>
  )
}


export function ReachComponent(props){
  const is_mobile = props.is_mobile;
  return (
    <div className="text-center">
      {(is_mobile)? (<hr className="highlight-white-4 "/>) : ('')}


      <div className="my-2">
        <FooterSectionTitle title="Follow"/>
        <div className="group-vertical my-1">
        <SocialComponent contact={PROFILE.contact} />
        </div>
      </div>

      <hr className="highlight-white-4 white-bb-1-1"/>

      <div className="my-2">
        <FooterSectionTitle title="Contact"/>
        <div className="group-vertical my-1">
          <a className="mx-2" href="mailto:info@bytebeacon.com"><i className="fa fa-envelope"></i></a>
          <a className="mx-2" href="https://join.slack.com/t/bytebeacon/shared_invite/enQtODAxNTUwODM0MjU4LTU3NDFiMGYzMDgyMDY0OWM3YzAzOWMyODlhYjM4NGY4Zjc1N2U0MWM0N2UyMGVkNTIyY2IwZjkxMGM3ZDRmY2E"><i className="fab fa-slack-hash"></i></a>
          <a className="mx-2" href="https://angel.co/company/bytebeacon"><i className="fab fa-angellist"></i></a>
        </div>
      </div>

    </div>
  )
}
