import React from 'react';
import {Link} from 'react-router-dom';

import SocialComponent from './../reuse/social';
import { PROFILE } from "./../constant";
import {CopyrightFooter, CompanyComponent, OtherProductsComponent, ReachComponent} from './common';
import SelfPromo from 'component/content/self_promo';


export function LoggedInFooter(props){
  return(
    <footer className={"gradient-dark"}>
      <div className="col"></div>
      <div className="col">
        <div className="text-white fa-pull-left  family-code ">
          <div className="pt-4 pb-2 uppercase text-spacing-2">
            <Link to="/">Home</Link>
            <span className="mx-2 text-white">|</span>
            <Link to="/legal/privacy">Privacy</Link>
            <span className="mx-2 text-white">|</span>
            <Link to="/legal/tos">Terms</Link>
          </div>
          <div className="pt-2 pb-4">
            © { new Date().getFullYear() } - <Link className=" uppercase text-spacing-2" to="/">ByteBeacon, Inc</Link> - All rights reserved
          </div>
        </div>
      </div>
      <div className="col"></div>
      <div className="col">
        <div className="fa-pull-right">
          <div className=" py-5">
          <SocialComponent contact={PROFILE.contact} />
          </div>
        </div>
      </div>
      <div className="col"></div>
    </footer>
  )
}


export function LandingFooter(props){
  const is_mobile = window.innerWidth < 1200;
  return(
    <footer>
      <div  className="p-1 pb-5 bg-footer box-shadow text-black-9">
        <div className="row">
          <div className="col-sm-5 px-0"><AboutComponent is_mobile={is_mobile} /></div>
          <div className="col-sm-2 px-0"><CompanyComponent is_mobile={is_mobile} /></div>
          <div className="col-sm-2 px-0"><OtherProductsComponent is_mobile={is_mobile} /></div>
          <div className="col-sm-3 px-0"><ReachComponent is_mobile={is_mobile} /></div>
        </div>
      </div>
      <SelfPromo />
      <CopyrightFooter padding={2} />
    </footer>
  )
}


// function CopyrightFooter(props){
//   return (
//     <div className={" family-code bg-dark text-center py-" + props.padding}>
//       <div className="text-white">© { new Date().getFullYear() } - <Link className=" uppercase text-spacing-2" to="/">ByteBeacon, Inc</Link> - All rights reserved.</div>
//     </div>
//   )
// }


function AboutComponent(props){
  return (
    <div className="">
      <div className="m-1 mt-5">
        <div className="my-3 ">
          <h2 className="text-spacing-5 family-title ">
            <img style={{height: "40px"}} className="hero mx-2 " src="/logo.svg" alt="product logo" />
            <span className="text-dark">Navigator</span>
          </h2>
        </div>
        <div className=" text-spacing-1 family-code text-black-2">
          <p className="">Give your users superpowers!</p>
          <p className="">Navigator provides a quick way to find information on your website or about your service offering.</p>
          {/* <p className=""> Navigator also provides support for predefined keywords for often-used sites and personalized prioritization per user based on their search patterns.</p> */}
          {/* <p className="">This is just the beginning of your users new Navigation experience!</p> */}
        </div>
      </div>

      {/* <hr className="highlight-white-4 "/> */}
{/* 
      <div className="m-3">
        <SubscribeForm full_width={true} />
      </div> */}

    </div>
  )
}

// function Links1Component(props){
//   const is_mobile = props.is_mobile;
//   return (
//     <div className={" text-center" + (is_mobile)? ' text-center': " m-1"}>
//       <FooterSectionTitle title="Get Started"/>
//       <div className="">
//         <FooterLink to="/" text="Home" />
//       </div>
//     </div>
//   )
// }

// function Links2Component(props){
//   const is_mobile = props.is_mobile;
//   return (
//     <div className={" text-center" + (is_mobile)? ' text-center': " m-1"}>
//       <FooterSectionTitle title="Company"/>
//       <div className="">
//         <FooterLink to="/" text="Home" />
//         <FooterLink to="/legal/privacy" text="Privacy" />
//         <FooterLink to="/legal/tos" text="Terms" />
//       </div>
//     </div>
//   )
// }


// function OtherProductsComponent(props){
//   const is_mobile = props.is_mobile;
//   return (
//     <div className={" text-center" + (is_mobile)? ' text-center': " m-1"}>
//       <FooterSectionTitle title="Other products"/>
//       <div className="">
//         <FooterExtLink href="https://divshow.bytebeacon.com" text="DivShow" />
//         <FooterExtLink href="https://softcomm.bytebeacon.com" text="SoftComm" />
//         <FooterExtLink href="https://bytepost.bytebeacon.com" text="BytePOST" />
//       </div>
//     </div>
//   )
// }

// function ReachComponent(props){
//   const is_mobile = props.is_mobile;
//   return (
//     <div className="text-center">
//       {(is_mobile)? (<hr className="highlight-white-4 "/>) : ('')}

//       <div className="my-2 ">
//         <FooterSectionTitle title="Follow"/>
//         <div className=" my-1">
//         <SocialComponent contact={PROFILE.contact} />
//         </div>
//       </div>

//       {/* <hr className="highlight-white-4 "/> */}

//       <div className="my-2 ">
//         <FooterSectionTitle title="Contact"/>
//         <div className=" my-1">
//           <a className="small mx-2" href="mailto:info@bytebeacon.com"><i className="fa fa-envelope fa-2x"></i></a>
//           <a className="small mx-2" href="https://join.slack.com/t/bytebeacon/shared_invite/enQtODAxNTUwODM0MjU4LTU3NDFiMGYzMDgyMDY0OWM3YzAzOWMyODlhYjM4NGY4Zjc1N2U0MWM0N2UyMGVkNTIyY2IwZjkxMGM3ZDRmY2E"><i className="fab fa-slack-hash fa-2x"></i></a>
//           <a className="small mx-2" href="https://angel.co/company/bytebeacon"><i className="fab fa-angellist fa-2x"></i></a>
//         </div>
//       </div>

//       {/* <hr className="highlight-white-4 "/> */}

//     </div>
//   )
// }
