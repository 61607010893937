import React from 'react';
import API, {handleError} from 'utils/xhr';


export default class Component extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      api_data: null,
    }
  }

  componentDidMount(){
    if (this.state.api_data !== null){
      return;
    }

    const url = '/configs/bb_promo';
    API.get(url).then(response =>{
      const state = this.state;
      state.api_data = response.data.data;
      this.setState(state);
    },(error) =>{
      handleError(error, this.props.history, window.location.pathname);
    })
  }

  render (){

    if (this.state.api_data === null){return '';}

    const products = this.state.api_data;

    return (
      <section id="self_promo" className="content-section flex-center bg-light py-3">
        <h2 className="text-center">Checkout some of our other products</h2>
        <div className="row">
          {products.map(function(item, idx){
            return (
              <div key={"_"+idx} className="col">
                <div className="" style={{display: 'grid', justifyItems: 'center', alignItems: 'center'}}>
                  <h5 className="family-h4 card-text my-4">{item.name}</h5>
                  <a href={item.url} className="">
                    <img src={"https://bytebeacon.com/static/images/"+item.key+"/logo.svg"} alt="" style={{width: '80px', height: '80px'}}/>
                  </a>
                  <p className="py-2 text-center">{item.text}</p>
                </div>
              </div>
            )
          })}
        </div>
      </section>
    )
  }
}