import React from 'react';

export function LoaderCircle(props){
  if (props.fetching_data){
    return (<img src="/images/gif/loader_circle.gif" alt="Loader"/>)
  }
  return (<div className=""></div>)
}


export function LoaderRipple(props){
  if (props.fetching_data){
    return (<img src="/images/gif/loader_ripple.gif" alt="Loader"/>)
  }
  return (<div className=""></div>)
}
