import React from 'react';

import Nav from 'component/navigation';
import { hasStoredToken } from '../../utils/authentication';
import {LandingFooter, LoggedInFooter} from 'component/footer';


export default function ContentLayout(props) {
  return (
    <div id={props.id} className="page-wrapper">

      <Nav />

      <div className="page-content my-5">
        {props.children}
      </div>

      {(hasStoredToken()? (<LoggedInFooter />) : (<LandingFooter />))}
    </div>
  );
}
