import React from "react";
import Slide from 'react-reveal/Slide';
import CodeBlock from 'react-copy-code';

import {SectionTitle} from 'component/reuse/title';


export default function ComponentTry(props){

  return (
    <div id="try" className="content-container my-5">
      <div className="container-fluid">
        <div className="my-3">
          <SectionTitle first="Try Navigator demo" second="" background="primary" LightSpeed={true} /> 
        </div>

        <div className="row">
        <Slide left>
          <div className="col-md-6 ">
            <CodeBlock>
          <pre className="rounded">
            <code > 
              &lt;script src="https://navigator.bytebeacon.com/demo/navigator.js" /&gt;
              <br />
              &lt;script&gt;
              <br />
                {/* {"var navigator = Navigator.createWidget({"}<br />
                {"    api_key: 'demo_social',"}<br />
                {"    theme: {colour: 'black'},"} <br />
                {"    data: {"}<br />
                {"      twitter: 'YOUR_ID_HERE',"}<br />
                {"      facebook: 'YOUR_ID_HERE',"}<br />
                {"      youtube: 'YOUR_ID_HERE',"}<br />
                {"      linked_in: 'YOUR_ID_HERE',"}<br />
                {"      instagram: 'YOUR_ID_HERE',"}<br />
                {"    }"}<br />
                {"  })"}<br /> */}

                {"    var navigator = Navigator.createWidget({api_key: 'demo_social'})"}<br />

              &lt;/script&gt;
            </code>
          </pre>
              </CodeBlock>
              </div>
          </Slide>
          <div className="col-md-6">
          {/* <Slide right> */}
            <p className="my-1">
              Copy and paste the script tags after the <code>&lt;body&gt;</code> section in your <code>html</code> files.
            </p>
            <p className="my-1">
              You can then use <span className="keyboard">ctrl</span>+<span className="keyboard">space</span> to view your smart search bar
            </p>
            {/* <p className="my-3">
              <strong>This DEMO code </strong> is to show you how easy the integration can be. The actual integration will span searching through all your website internals, pointers to external links relating to your website (blogs, news posts, public listings such as Product hunt pages etc).
            </p> */}
            {/* <p className="my-3">
              Please signup or get in touch with us to get early access.
            </p> */}
            <p className="my-1">
              <a href="https://codepen.io/catalysticinfo/pen/BayRqZR">Codepen example</a>
            </p>
            
            {/* </Slide> */}
          </div>
        </div>


      </div>
    </div>
  )
}