import React from 'react';
import { withRouter} from 'react-router-dom';
// import CookieConsent from "react-cookie-consent";

import LandingLayout from 'component/page/landing';
import Hero from 'component/content/hero';
import About from 'component/content/about';
import Colours from 'component/content/colours';
import Try from 'component/content/try';
import SubscribeCTA from 'component/content/subscribe';


class Page extends React.Component {
  render (){
    return (
      <LandingLayout id="landing-page">
        {/* <CookieConsent>
          This website uses cookies to enhance the user experience.
        </CookieConsent> */}

        <Hero />
        <About />
        
        {/* <SubscribeCTA /> */}
        <Try />

        <div className="py-1 my-1"></div>
        
        <Colours />

        <div className="slant slant-top-left-secondary-1"></div>
        <div className="highlight-secondary-1">
        <SubscribeCTA />
        </div>
        {/* <div className="slant slant-bottom-right-secondary-1"></div> */}

        {/* <CTA /> */}

      </LandingLayout>
    )
  }
}

export default withRouter(Page);
