import React from "react";
import { SectionTitle } from 'component/reuse/title';

import CodeBlock from 'react-copy-code';

import API, {handleError} from 'utils/xhr';


export default class Section extends React.Component {

  state = {
    email: '',
    links: [],
    showcode: false,
    generated_api: null,
  }

  onAdd(){
    const state = this.state;
    state.links.push({
      'url': '',
      'name': '',
    })
    this.setState(state)
  }

  onEmailChange(e){
    const state = this.state;
    state.email = e.target.value;
    state.generated_api = null;
    state.showcode = false;
    this.setState(state);
  }

  onChange(e){
    const state = this.state;
    state.links[parseInt(e.target.dataset.idx)][e.target.name] = e.target.value;
    state.generated_api = null;
    state.showcode = false;
    this.setState(state);
  }

  onSave(e){
    const url = '/navigator_key/';
    const data = {
      email: this.state.email,
      links: this.state.links,
    }
    API.post(url, data).then(response =>{
      const state = this.state;
      state.showcode = true;
      state.generated_api = response.data.data;
      this.setState(state);
    },(error) =>{
      handleError(error, this.props.history, window.location.pathname);
    })
  }

  render (){

    return (
      <section id="build" className=" my-5">

        <div className="text-center">
          <SectionTitle first="Build your own Navigator widget" second="" flip={true}/>
          <p className="small text-muted">Please note: This widget is a static widget. It'll have links that you manually maintain. For a dynamic widget, where we regularly index your website, please signup for early access!</p>
          <hr className=""/>
        </div>

        <div className="containera">

          <div className="row">
          <div className=" col-sm-12 col-md-6">

            <div className="form-group">
              <label className="small">Email (optional, but helpful in adding a contact entry in your widget): </label>
              <input 
                className="form-control" 
                name="email" 
                placeholder="Your email address"
                onChange={this.onEmailChange.bind(this)}
                value={this.state.email} />
            </div>

          {this.state.links.map(function(item, idx){
            return(
              <div key={"_id"+idx} className="">

                {/* {(idx === 0)? '': <hr/>} */}
                <hr className=""/>
                
                <div className="form-group">
                  <label className="small">Link name {idx+1}: </label>
                  <input 
                    className="form-control" 
                    name="name" 
                    data-idx={idx}
                    placeholder="Link name to display in the widget. e.g: LinkedIn"
                    onChange={this.onChange.bind(this)}
                    value={item.name} />
                </div>
                <div className="form-group">
                  <label className="small">URL {idx+1}: </label>
                  <input 
                    className="form-control" 
                    name="url" 
                    data-idx={idx}
                    placeholder="e.g: https://www.linkedin.com/company/bytebeacon"
                    onChange={this.onChange.bind(this)}
                    value={item.url} />
                </div>

              </div>
            )
          }, this)}

          <div className="my-1">
            <button onClick={this.onAdd.bind(this)} className="btn btn-info"><i className="fa fa-plus mr-2"></i>Add link</button>
          </div>

          </div>
          
          <div className=" col-sm-12 col-md-6">
            <div className="my-1">
              {(this.state.generated_api === null)? (
                <button onClick={this.onSave.bind(this)} className="btn btn-success">Save and show code</button>
              ): (
                <NavigatorCode api_key={this.state.generated_api} />
              )}

            </div>
          </div>
          </div>

        </div>

      </section>
    )
  }
}



function NavigatorCode(props){
  return (
    <div className="">
      <CodeBlock>
        <pre className="rounded">
          <code > 

          {'<script src="https://navigator.bytebeacon.com/sdk/0.0.1/navigator.min.js"></script>'}
          <br/>
          {'<script>'}
          <br/>
          {"  var navigator = Navigator.createWidget({theme: {colour: 'black'}, api_key: '"+props.api_key+"'})"}
          <br/>
          {'</script>'}

          </code > 
        </pre>
      </CodeBlock>
      <p className="text-muted">Copy and paste this code in any html page and you can use <kbd>ctrl</kbd>+<kbd>space</kbd> to launch your own widget!</p>
    </div>
  )
}